import React from 'react';
import { Layout } from '../layouts/Layout';
import { SEO } from '../components/SEO';
import { StaticImage } from 'gatsby-plugin-image';

const Product = ({ img, title, description, href = '#' }) => {
  return (
    <a
      className="flex flex-col gap-2 transition-all translate-y-0 cursor-pointer hover:-translate-y-1"
      href={href}
      target="_blank"
    >
      {img}
      <h2 className="mt-4 text-2xl font-extrabold">{title}</h2>
      <h3 className="text-lg font-thin">{description}</h3>
    </a>
  );
};
export default function ShopPage() {
  return (
    <Layout>
      <SEO title="Shop" />
      <div className="flex flex-col items-center justify-center w-full h-full gap-20 p-6 md:gap-6 xl:px-12 md:grid md:grid-cols-2">
        <Product
          href="http://naver.me/GUQOda65"
          img={
            <StaticImage
              src="../resources/shop/decal01.jpg"
              alt="A dinosaur"
              placeholder="blurred"
              className="w-full rounded-lg"
              // layout="fixed"
              style={{ width: '100%', height: 'auto' }}
            />
          }
          title="WLPO 로고 데칼 스티커"
          description={
            <>
              화이트, 블랙, 옐로우 <small>(스몰/미디움)</small>
            </>
          }
        />
        <Product
          href="https://smartstore.naver.com/wlpo/products/6550547548"
          img={
            <StaticImage
              src="../resources/shop/hoodie01.jpg"
              alt="A dinosaur"
              placeholder="blurred"
              className="w-full rounded-lg"
              // layout="fixed"
              style={{ width: '100%', height: 'auto' }}
            />
          }
          title="WLPO 베이직 로고 오버핏 후디 "
          description={
            <>
              블랙 <small>(L/XL)</small>
            </>
          }
        />
      </div>
    </Layout>
  );
}
